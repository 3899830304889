import React, { Component }  from 'react'
import { Root, Routes } from 'react-static'
import { Link } from '@reach/router'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './app.css';
import $ from 'jquery';

class App extends Component {
  constructor(props) {
        super(props);
      
      this.throttledHandleWindowResize = this.throttledHandleWindowResize.bind(this);
  }
componentDidMount() {     
    if(window.innerWidth < 370){
        $('.navbar').addClass('mobile_w700'); 
    }      
    else{
        $('.navbar').removeClass('mobile_w700');
    }
         
    
    if(window.innerWidth < 768){
        $('.navbar').addClass('mobile_w768'); 
        $('.content').addClass('mobile_w768');       
    }      
    else{
        $('.navbar').removeClass('mobile_w768');
        $('.content').removeClass('mobile_w768');       
    }
         
     window.addEventListener('resize', this.throttledHandleWindowResize);
}
throttledHandleWindowResize(){
    if(window.innerWidth < 370){
       $('.navbar').addClass('mobile_w700');  
    }      
    else{
        $('.navbar').removeClass('mobile_w700');
    }
          
    if(window.innerWidth < 768){
        $('.navbar').addClass('mobile_w768'); 
        $('.content').addClass('mobile_w768');       
    }      
    else{
        $('.navbar').removeClass('mobile_w768');
        $('.content').removeClass('mobile_w768');       
    }   
    
}
render() {
  return (
    <Root>   
      <nav className='navbar fixed-top navbar-light bg-white'>
        <Link to="/" className='logolink'><img className='logo' src='https://voicesofothers.s3.eu-west-3.amazonaws.com/3fe3050aedd544b1adf86b096cb764ed.png' height='34' width='auto' /></Link>
        <h4 id='sottotitolo'>interviews project</h4>
        <div className='nav-links'>
            <Link to="/voices">VOICES</Link>
            <Link to="/about">ABOUT</Link>
            <Link to="/contact">CONTACT</Link>
        </div>
      </nav>
      <div className="content">
        <Routes />
      </div>
    </Root>
  )
}
}

export default App;
